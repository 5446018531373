import { extendTheme } from "@chakra-ui/react"


import { StepsStyleConfig } from 'chakra-ui-steps';
import {darken, lighten, mode} from "@chakra-ui/theme-tools";
import React from "react";


const baseStyleStepIconContainer  = props => {
    const { colorScheme: c } = props;
    const inactiveColor = mode('#00D0FF', '#00D0FF')(props);
    const activeColor = `${c}.500`;

    return {
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        bg: inactiveColor,
        borderColor: inactiveColor,
        transitionProperty: 'background, border-color',
        transitionDuration: 'normal',
        _activeStep: {
            bg: '#FFA200', //mode(darken(inactiveColor, 0.5), lighten(inactiveColor, 0.5))(props),
            borderColor: 'white',
            _invalid: {
                bg: 'red.500',
                borderColor: 'red.500',
            },
        },
        _highlighted: {
            bg: activeColor,
            borderColor: activeColor,
        },
        '&[data-clickable]:hover': {
            borderColor: activeColor,
        },
    };
};



const CustomSteps = {
    ...StepsStyleConfig,
    baseStyle: props => {
        return {
            ...StepsStyleConfig.baseStyle(props),
            label: {
                ...StepsStyleConfig.baseStyle(props).label,
                // your custom styles here
                color: "white"
            },
            icon: {
                ...StepsStyleConfig.baseStyle(props).icon,
                // your custom styles here
                strokeWidth: '1px',
            },
            stepIconContainer: {
                ...baseStyleStepIconContainer(props),
            }
        };
    },
};



const Button= {
    // The styles all button have in common
    baseStyle: {
        fontWeight: 'bold',
        // textTransform: 'uppercase',
    },
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: 'sm',
            borderRadius:'2xl',
            px: 4, // <-- px is short for paddingLeft and paddingRight
            py: 3, // <-- py is short for paddingTop and paddingBottom
        },
        md: {
            fontSize: 'md',
            borderRadius:'3xl',
            height:50,
            width:150,
            px: 6, // <-- these values are tokens from the design system
            py: 4, // <-- these values are tokens from the design system
        },
    },
    // Two variants: outline and solid
    variants: {
        outline: {
            border: '1px solid',
            borderColor: 'primary',
            color: 'primary',
        },
        grad: {
            color: 'white',
            bgGradient:'linear(to-r, #FF6F00, #FF8F00)',
            _hover:{
                    bgGradient: 'linear(to-l, #FF6F00, #FF8F00)',
}
        },
    },
    // The default size and variant values
    defaultProps: {
        size: 'md',
        variant: 'outline',
    },
}



const theme = {
    colors: {
       primary: "#0C73B5",
    },
    components: {
        Steps: CustomSteps,
        Button,
    },
}

export default extendTheme(theme)
