module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["am","en"],"defaultLanguage":"am","siteUrl":"http://172.23.59.71","i18nextOptions":{"interpolation":{"skipOnVariables":false}},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/preview","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"http://172.23.59.71/ema/graphql","headers":{"Authorization":"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNzIzMmU5NjIxNWIzMzA0MGEyMzRkZiIsInJvbGVzIjpbIlNBIl0sImlhdCI6MTY2ODQyODUyMX0.VkstKrMM-3CgMwEGWyWZfIec6q1NaiH_YtoDEKrEJjQ"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Email Complaint Handling System","short_name":"EMA","start_url":"/","background_color":"#f7f0eb","theme_color":"#14245d","display":"standalone","icon":"src/images/ema_logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"129548ad77e57241272a757cb2f5d3e5"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
